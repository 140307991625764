import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment as env } from 'src/environments/environment';

import { ConfigModel } from '../models/config.model';

@Injectable()
export class ConfigService {
  private _config: ConfigModel | undefined;

  readonly configPath: string;
  readonly http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
    this.configPath = this.getConfigPath();
  }

  loadConfig$() {
    return this.http.get<ConfigModel>(this.configPath).pipe(
      tap((settings) => {
        this._config = settings;
      })
    );
  }

  get config(): ConfigModel | undefined {
    return this._config;
  }

  getConfigPath(): string {
    const { stage } = env;

    const stages = new Map<string, string>([
      ['production', 'prod'],
      ['homologation', 'homolog']
    ]);

    const currentStage = stages.get(stage);

    const basePath = currentStage
      ? `./assets/static/config.${currentStage}.json`
      : `./assets/static/config.json`;

    const timestamp = new Date().getTime();

    return `${basePath}?v=${timestamp}`;
  }
}
